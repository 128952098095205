import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import DataProtectionMeasures from "../components/data-protection-measures"
import FeatureColumn from "../components/feature-column"
import Testimonials from "../components/testimonials"
import FeatureObjectTypes from "../components/feature-object-types"
import DeploymentOptions from "../components/deployment-options"
import Demo from "../components/demo"
import WhyCelantur from "../components/why-celantur"
import MoreBlogPosts from "../components/more-blog-posts"
import SeagateCard from "../components/seagate-card"
import PgrBlurringCard from "../components/pgr-blurring-card"
import ActionForm from "../components/action-form"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChartLine,
  faEuroSign,
  faArrowRight,
  faBalanceScale,
  faHandshake,
  faCogs,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons"

const MobileMapping = ({ data }) => (
  <Layout>
    <SEO
      title="Automated Blurring for Mobile Mapping | Celantur"
      description="High-quality and scalable image anonymization solution to automatically blur faces, bodies, license plates on panorama and planar images"
    />

    <section
      className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-50 bg-cover text-center"
      style={{
        background: "url(/images/rgb-city-cars-bodies.png) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <h1
          className="display-2 font-weight-bold text-white"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          Automated <span className="text-success">Blurring</span>
          <br />
          for Mobile Mapping
        </h1>
        <p
          className="lead mb-6 mb-md-8 text-gray-200"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          High-quality and scalable image anonymization solution.
          <br /> Automatically blur faces, bodies, license plates on panoramas,
          planar images and videos
        </p>
        <a
          href="https://app.celantur.com/"
          className="btn btn-success lift event-start-demo"
        >
          Start Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </a>
        <Link
          to="/contact/"
          className="btn btn-outline-light ml-3 lift event-contact"
        >
          Contact Us
        </Link>
      </div>
    </section>

    <section className="pt-5 pb-8">
      <Customers />
    </section>

    <section className="pt-5 pb-5">
      <div className="container">
        <FeatureObjectTypes />
      </div>
    </section>

    <section className="pt-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 mb-5 text-center">
            <h2 className="font-weight-bold">
              Mobile Mapping Privacy Challenges <span className="text-primary">easily solved</span> 
            </h2>
            <p className="font-size-lg text-muted mb-6">
              By using Celantur, you can fulfill your customer's data protection demands while saving time and money.
            </p>
          </div>
        </div>
        <div className="row">
          <FeatureColumn headline="Quality" icon={faHandshake}>
            Industry-grade detection rate, trusted by global market leaders.
          </FeatureColumn>
          <FeatureColumn
            headline="Scalable and Fast"
            icon={faDatabase}
          >
            Handle massive datasets with ease.
          </FeatureColumn>
          <FeatureColumn headline="Easy Integration" icon={faCogs}>
            Many options to integrate Celantur into your data processing workflows.
          </FeatureColumn>
        </div>
        <div className="row">
          <FeatureColumn headline="GDPR-compliance" icon={faBalanceScale}>
            Comply with data protection laws like the GDPR,
            CCPA, etc.
          </FeatureColumn>
          <FeatureColumn headline="More Business" icon={faChartLine}>
            Meet customer demands for new contracts and tenders
          </FeatureColumn>
          <FeatureColumn headline="Save Costs" icon={faEuroSign}>
            Fully automated anonymization, no manual labor
            involved.
          </FeatureColumn>
        </div>
      </div>
    </section>
    
    <section className="py-5 py-md-8">
      <div className="container">
        <DeploymentOptions />
      </div>
    </section>

    <section className="py-8">
      <div className="container">
        <ActionForm source="mobile-mapping-landing-page" />
      </div>
    </section>

    <Testimonials all={true} />

    <Demo />

    <DataProtectionMeasures />
    
    <section className="py-8 py-md-11">
      <SeagateCard />
      <PgrBlurringCard />
    </section>

    <WhyCelantur />

    <section className="py-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-md-8 text-center">
            <h2 className="font-weight-bold">Frequently Asked Questions</h2>
            <p className="font-size-lg text-muted mb-7 mb-md-9">
              Let us help answer the most common questions you might have
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3>Is 100% anonymization achievable?</h3>
            <p className="text-muted mb-6 mb-md-8">
              We’re using state-of-the-art technology (neural networks), but no
              technology works 100% perfectly. No fully automated solution can
              guarantee a 100% “True Positive Rate”. However, most of the public
              tenders require between 95% (e.g. Munich) and 99% (e.g. Vienna)
              True Positive Rate.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>
              What happens to the metadata of the original image (e.g. GPS
              coordinates)?
            </h3>
            <p className="text-muted mb-6 mb-md-8">
              Metadata (e.g. EXIF information &amp; ICC colour profile) is
              retained when creating the anonymized version of an image. Also,
              we can generate{" "}
              <a href="https://doc.celantur.com/container/usage/segmentation-masks-and-metadata" target="_blank" rel="noopener noreferrer">
                segmentation mask
              </a>{" "}
              files per processed image.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3>Which Mobile Mapping Systems (MMS) do you support?</h3>
            <p className="text-muted mb-6 mb-md-8">
              We can anonymize nearly{" "}
              <mark>all kinds of image and video data</mark>.<br />
              Here's an incomplete list of MMS brands our customers frequently
              use:
              <ul>
                <li>Trimble</li>
                <li>Leica</li>
                <li>NavVis (no dng file supported)</li>
                <li>GeoSLAM</li>
                <li>Topcon</li>
                <li>Viametris</li>
                <li>Riegl</li>
                <li>...</li>
              </ul>
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>When to use Cloud Service or the Container?</h3>
            <p className="text-muted mb-6 mb-md-8">
              It depends on your use case, amount of data, frequency of usage
              and hardware availability. We are happy to help with finding the
              perfect fit for you.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container text-center text-muted">
        <p>
          Read our article{" "}
          <a
            href="https://www.gim-international.com/content/article/image-anonymization-for-mobile-mapping"
            target="_blank"
            rel="noreferrer"
          >
            "Image Anonymization for Mobile Mapping"
          </a>{" "}
          in GIM International
        </p>
      </div>
    </section>

    <MoreBlogPosts caption="Latest Blog Posts on Mobile Mapping" posts={data} />

    <section className="container my-5 text-center">
      {" "}
      {/* CTA */}
      <Link
        to="/contact/"
        role="button"
        className="btn btn-lg btn-success my-2 event-contact"
      >
        Contact Us
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Link>
    </section>
  </Layout>
)

export default MobileMapping

export const query = graphql`
  query MobileMappingBlogPosts {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["mobile mapping"] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            path
            teaser
            date
          }
        }
      }
    }
  }
`
