import React from "react"
import { Link } from "gatsby"

const PgrBlurringCard = () => {
  return (
    <>
      <div className="container">
        <div className="alert alert-secondary d-flex flex-column flex-lg-row flex-xl-row">
          <div className="p-2 ml-3">
            <span className="badge badge-pill badge-light-soft mb-3">
              <span className="h6 text-uppercase">Integration</span>
            </span>
            <h3 className="alert-heading">Blur Ladybug PGR imagery</h3>
            <p>
              Use{" "}
              <a
                href="https://horus.nu/integration-accelerator/"
                target="_blank"
                rel="noreferrer"
                className="text-reset"
              >
                Horus Horison
              </a>{" "}
              Batch Processing with{" "}
              <Link to="/container/" className="text-reset">
                Celantur Container
              </Link>{" "}
              to blur Teledyne Flir PGR images.
            </p>
            <a
              href="https://doc.celantur.com/tutorials/anonymize-teledyne-flir-pgr-images"
              className="btn btn-sm btn-outline-light"
            >
              Read How To
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default PgrBlurringCard
